import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = ({ location, data}) => {
  const {
    site: {
      siteMetadata: {
        title: siteTitle
      }
    }
  } = data;
  return (
    <Layout location={location}>
      <SEO title={`404 | ${siteTitle}`} />
      <section className="four-oh-four" style={{padding: '80px 0px 100px'}}>
        <div className="wrapper">
          <h1>NOT FOUND</h1>
          <p>The page you are trying to access does not exist.</p>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage

export const pageQuery = graphql`
  query FourOhFourQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
